body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* select pitch modal css */

.pitch-modal {
  width: 100% !important;
  max-width: 675px !important;
}

.pitch-modal-header {
  font-size: 19px !important;
  font-weight: 700 !important;
  text-align: center;
}

.pitch-modal-body {
  padding: 0px 62px 27px 62px !important;
}

.pitch-list-hover {
  transition: all 240ms ease-in;
}

.pitch-list-hover:hover {
  border: 1px solid #8B5971 !important;
  border-radius: 5px !important;
  background: #8b59710d !important;
}

.pitch-conform-modal {
  width: 100% !important;
  max-width: 331px !important;
  padding: 25px !important;
}

.pitch-conform-modal-header {
  font-size: 19px !important;
  font-weight: 700 !important;
  text-align: center;
  padding-bottom: 0px !important;
}

.pitch-conform-modal-body {
  padding: 0px !important;
}

/* Scroll Bar */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #787878;
  border-radius: 20px;
}

/* Input Fields */
.app-input {
  width: 100%;
}

.app-input input {
  /* border-radius: 4px; */
  padding: 4px 12px;
  /* border: 1px solid #DADCDE; */
  /* background-color: #F4F6FA; */
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 40px;
}

.app-input .right-input {
  border-radius: 0px 4px 4px 0px;
}

.app-input .remove-right-border {
  border-radius: 0px 4px 4px 0px;
  border-left: 0px;
}

.app-input .left-addon {
  border-color: #DADCDE;
}

.app-calendar th,
.app-calendar td {
  padding: 0 !important;
}

.fc-scrollgrid-sync-inner {
  padding: 10px 20px !important;
}

/* commet chat css (unread msg count) */
.cc__badge {
  color: #ffff !important;
}

/* .cc-conversations__subtitle-view {
  margin-top: 1px !important;
} */

.cc__list__item {
  min-height: 52px !important;
}

.cc__item__thumbnail {
  margin: 10px 7px !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.cometchat-translucent-loader {
  opacity: 0;
  animation: fadeInOutAnimation 2s ease-in-out infinite;
  z-index: 99;
}


@keyframes fadeInOutAnimation {
  0% {
    opacity: 10%;
  }

  40%,
  60% {
    opacity: 15%;
  }

  100% {
    opacity: 10%;
  }
}

@media screen and (max-width: 992px) {
  .pitch-modal-body {
    padding: 0px 19px 30px 19px !important;
  }

  .pitch-modal {
    margin: auto 30px !important;
  }

  .pitch-conform-modal {
    top: 14% !important;
  }
}

@media screen and (max-width: 480px) {
  .class-details td {
    padding: 16px 10px;
  }
}